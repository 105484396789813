import { useSelector } from 'react-redux';

import { useEffect, useState } from 'react';

import { Button, Chip, CircularProgress, FormControl, FormLabel, Grid, IconButton, Input, Option, Select, Stack, Table, Tooltip, Typography } from '@mui/joy';
import Box from '@mui/joy/Box';

import MapIcon from '@mui/icons-material/Map';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SearchIcon from '@mui/icons-material/Search';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';

import moment from 'moment';
import { Sidebar } from '../../components/Sidebar';

import { Layout } from '../../components/Layout';
import { Header } from '../../components/Header';
import Pagination from '../../components/Pagination';

import { ModalMostrarMapa } from '../../modals/ModalMostrarMapa';
import { ModalNotificacion } from '../../modals/ModalNotificacion';

import { RootState } from '../../store';

import { ConsultarNotificaciones, Notificaciones } from '../../connections/comun/NotificacionConnection';
import { ObtenerEstatus } from '../../connections/catalogos/EstatusConnection';
import { ObtenerActuarios } from '../../connections/admin/UsuarioConnection';

import { NotificacionFullProps } from '../../interfaces/comun/NotificacionInterface';
import { EstatusInterface } from '../../interfaces/catalogos/EstatusInterface';
import { UsuarioProps } from '../../interfaces/admin/UsuarioInterface';

export const NotificacionesPage = () => {

    const { administrador, puesto } = useSelector( ( state: RootState ) => state.auth );

    const [searching, setSearching] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [openMapa, setOpenMapa] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);

    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [recordsCount, setRecordsCount] = useState<number>(0);

    const [referencia, setReferencia] = useState<string>("")
    const [persona, setPersona] = useState<string>("")

    const [rows, setRows] = useState<NotificacionFullProps[]>([]);
    const [row, setRow] = useState<NotificacionFullProps>({});

    const [actuario, setActuario] = useState<UsuarioProps>({ id: '', idUsuario: 0, nombreCompleto: '', imagenPerfil: '', puesto: '', activo: false, administrador: false, correoElectronico: '', tipoUsuario: '' });
    const [actuarios, setActuarios] = useState<UsuarioProps[]>([]);

    const [estatus, setEstatus] = useState<EstatusInterface>({ idEstatus: 0, descripcion: '', activo: false });
    const [estatusArray, setEstatusArray] = useState<EstatusInterface[]>([]);

    const handleChangeEstatus = (_: React.SyntheticEvent | null, newValue: EstatusInterface | null,) => setEstatus(newValue ?? { idEstatus: 0, descripcion: '', activo: false })

    const handleChange = (_: React.SyntheticEvent | null, newValue: UsuarioProps | null,) => setActuario(newValue ?? { id: '', idUsuario: 0, nombreCompleto: '', imagenPerfil: '', puesto: '', activo: false, administrador: false, correoElectronico: '', tipoUsuario: '' })

    const handleBuscar = () => {

        setSearching(true);
        setPage(0);

    }

    const handleClearFields = () => {

        setRows([]);
        setPage(0);

        setEstatus({ idEstatus: 0, descripcion: '', activo: false });
        setActuario({ id: '', idUsuario: 0, nombreCompleto: '', imagenPerfil: '', puesto: '', activo: false, administrador: false, correoElectronico: '', tipoUsuario: '' });
        setReferencia('');
        setPersona('');
        setSearching(false);
        setIsLoading(true);
    }

    useEffect(() => {

        async function Obtener() {

            setIsLoading(true);

            await Notificaciones({ page, rowsPerPage }).then(resp => {

                setTimeout(() => {

                    setRows(resp.records);
                    setRecordsCount(resp.recordsCount);
                    setIsLoading(false);

                }, 400);

            })

        }

        if (!searching) {
            Obtener();
        }

    }, [page, rowsPerPage, searching])

    useEffect(() => {

        async function Obtener() {

            setIsLoading(true);

            await ConsultarNotificaciones({ page: page, rowsPerPage: rowsPerPage, actuario: actuario.idUsuario, estatus: estatus.idEstatus, personaNotificar: persona ?? "", referencia: referencia ?? "" }).then(resp => {

                setTimeout(() => {
                

                    setRows(resp.records);
                    setRecordsCount(resp.recordsCount);
                    setIsLoading(false);


                }, 400);

            })

        }

        if (searching) {
            Obtener();
        }

    }, [page, rowsPerPage, searching, actuario, estatus, referencia, persona])

    useEffect(() => {

        async function obtener() {

            await ObtenerActuarios().then(resp => {

                const defaultUsuario: UsuarioProps = { id: '', idUsuario: 0, nombreCompleto: 'Seleccione el actuario', imagenPerfil: '', puesto: '', activo: false, administrador: false, correoElectronico: '', tipoUsuario: '' };

                setActuarios([defaultUsuario, ...resp]);
            });

        }

        obtener();

    }, [])

    useEffect(() => {

        async function obtener() {

            await ObtenerEstatus().then(resp => {

                const defaultEstatus: EstatusInterface = { idEstatus: 0, descripcion: 'Seleccione el estatus', activo: false };

                setEstatusArray([
                    defaultEstatus,
                    ...resp.filter((e) => e.idEstatus === 3 || e.idEstatus === 4 || e.idEstatus === 5 || e.idEstatus === 6 || e.idEstatus === 7 || e.idEstatus === 8 || e.idEstatus === 9 || e.idEstatus === 10 || e.idEstatus === 11)
                ]);
            });

        }

        obtener();

    }, [])

    return (
        <Box sx={{ display: 'flex', minHeight: '100dvh' }}>

            <Sidebar />

            <Header />

            <Layout
                title={'Notificaciones'}
                isQuery={true}
            >

                <Grid container spacing={4}>

                    {
                        ( administrador || ( puesto !== 0 && puesto !== 3) )
                        &&
                            <Grid xs={12} md={3}>

                                <FormControl disabled={searching || persona !== "" || referencia !== ""}>
                                    <FormLabel sx={{ fontSize: 16, fontWeight: 'bold' }}>Actuario(s)</FormLabel>

                                    <Select
                                        size='lg'
                                        sx={{ width: '100%' }}
                                        slotProps={{
                                            listbox: {
                                                sx: {
                                                    width: '100%',
                                                    zIndex: 99999999
                                                },
                                            },
                                        }}
                                        value={actuario}
                                        onChange={handleChange}
                                        placeholder="Seleccione el actuario"
                                    >
                                        {
                                            actuarios.map((e) => (
                                                <Option key={e.idUsuario} value={e} sx={{ py: 1 }}>{e.nombreCompleto}</Option>
                                            ))
                                        }

                                    </Select>

                                </FormControl>

                            </Grid>
                    }

                    <Grid xs={12} md={3}>

                        <FormControl disabled={searching || persona !== "" || referencia !== ""}
                        >
                            <FormLabel sx={{ fontSize: 16, fontWeight: 'bold' }}>Estatus</FormLabel>
                            <Select
                                size='lg'
                                sx={{ width: '100%' }}
                                slotProps={{
                                    listbox: {
                                        sx: {
                                            width: '100%',
                                            zIndex: 99999999
                                        },
                                    },
                                }}
                                value={estatus}
                                onChange={handleChangeEstatus}
                                placeholder="Seleccione el estatus"
                            >
                                {
                                    estatusArray.map((e) => (
                                        <Option key={e.idEstatus} value={e} sx={{ py: 1 }}>{e.descripcion}</Option>
                                    ))
                                }
                            </Select>
                        </FormControl>

                    </Grid>

                    <Grid xs={12} md={2}>

                        <FormControl disabled={searching || persona !== "" || (actuario.idUsuario !== 0 || estatus.idEstatus !== 0)} >
                            <FormLabel sx={{ fontSize: 16, fontWeight: 'bold' }}>Expediente / Exhorto</FormLabel>
                            <Input size='lg' placeholder={"1/"+ ( new Date().getFullYear() ) }autoComplete='off'
                                value={referencia} onChange={(e) => setReferencia(e.target.value)}
                            />
                        </FormControl>

                    </Grid>

                    <Grid xs={12} md={4}>

                        <FormControl disabled={searching || referencia !== "" || (actuario.idUsuario !== 0 || estatus.idEstatus !== 0)} >
                            <FormLabel sx={{ fontSize: 16, fontWeight: 'bold' }}>Persona a Notificar</FormLabel>
                            <Input size='lg' placeholder="Juan perez" autoComplete='off'
                                value={persona} onChange={(e) => setPersona(e.target.value)}
                            />
                        </FormControl>

                    </Grid>

                    <Grid xs={12} textAlign={'end'}>

                        <Button
                            color='neutral'
                            variant='solid'
                            size='lg'
                            startDecorator={searching ? <SearchOffIcon /> : <SearchIcon />}
                            onClick={() => searching ? handleClearFields() : handleBuscar()}
                            disabled={actuario.idUsuario === 0 && referencia === '' && persona === '' && estatus.idEstatus === 0}
                        >
                            {searching ? 'Limpiar' : 'Buscar'}
                        </Button>

                    </Grid>

                    <Grid xs={12} md={12} mt={1}>

                        <Table
                            aria-label="striped table"
                            stripe={'even'}
                            borderAxis='x'
                            size='lg'
                            sx={{
                                width: { xs: '100%', md: '100%' },
                                display: { xs: 'block', md: 'inline' },
                                overflowX: { xs: 'auto', md: 'hidden' },
                            }}
                        >

                            <thead>

                                <tr>
                                    <th style={{ width: '3%', textAlign: 'center' }}>ID</th>
                                    <th style={{ width: '15%' }}>Persona a Notificar</th>
                                    <th style={{ width: '25%' }}>Domicilio</th>
                                    <th style={{ width: '10%' }}>Referencia</th>
                                    <th style={{ width: '20%' }}>Actuario asignado</th>
                                    <th style={{ width: '10%', textAlign: 'center' }}>Notificada</th>
                                    <th style={{ width: '15%', textAlign: 'center' }}>Estatus</th>
                                    <th style={{ width: '10%', textAlign: 'center' }}>Detalle</th>

                                </tr>

                            </thead>

                            <tbody>

                                {

                                    isLoading
                                        ?
                                        <tr>
                                            <td colSpan={9} style={{ textAlign: 'center', padding: '250px' }}>
                                                <CircularProgress color='neutral' size='md' />
                                            </td>
                                        </tr>
                                        :
                                        rows
                                            .map((row) => (

                                                <tr key={row.id}>

                                                    <td style={{ fontWeight: 'bold', textAlign: 'center' }}>{row.idNotificacion}</td>

                                                    <td>
                                                        <Typography fontSize={14} fontWeight='bold'> {row.persona} </Typography>
                                                        <Typography fontSize={14}> ({row.tipoParte}) </Typography>
                                                    </td>

                                                    <td>
                                                        {
                                                            row.lat !== 0
                                                                ?
                                                                <Button
                                                                    variant="plain"
                                                                    color="neutral"
                                                                    endDecorator={<MapIcon />}
                                                                    sx={{ textAlign: 'start' }}
                                                                    onClick={() => { setRow(row ?? []); setOpenMapa(true); }}
                                                                >
                                                                    {row.domicilio}
                                                                </Button>
                                                                :
                                                                <Typography fontSize={14} fontWeight='bold'> {row.domicilio} </Typography>

                                                        }
                                                    </td>

                                                    <td>
                                                        <Typography fontSize={14} fontWeight='bold'>{row.referencia} </Typography>
                                                        <Typography fontSize={14} mt={1}> ({row.tipoNotificacion}) </Typography>
                                                    </td>

                                                    <td>
                                                        <Typography level="title-md">{row.actuario}</Typography>
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>

                                                        <Chip variant='outlined' size='lg'>
                                                            {row.seNotifico ? 'Si' : 'No'}
                                                        </Chip>

                                                        {
                                                            (!row.seNotifico && row.estatus !== "Rechazada")
                                                                ?
                                                                <Typography fontSize={14} mt={1}>
                                                                    {row.motivo}
                                                                </Typography>
                                                                :
                                                                row.seNotifico
                                                                &&
                                                                <Typography mt={1} fontSize={12}> {row.fechaNotificacion ? moment(row.fechaNotificacion).format('DD-MM-YYYY h:mm a') : ''} </Typography>
                                                        }

                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>

                                                        <Stack direction={'column'}>


                                                            <Stack direction={'row'} justifyContent={'center'}>


                                                                <Chip variant='outlined' size='lg'>
                                                                    {
                                                                        row.estatus
                                                                    }
                                                                </Chip>
                                                                {
                                                                    (row.replanificar && row.estatus === 'Finalizada')
                                                                    &&
                                                                    <Tooltip title="Re-planificado" >

                                                                        <EventRepeatIcon sx={{ color: '#ffa138', alignSelf: 'center', ml: 1 }} />

                                                                    </Tooltip>
                                                                }

                                                            </Stack>
                                                            {
                                                                row.estatus === "Rechazada"
                                                                &&
                                                                <Typography fontSize={14} mt={1}>
                                                                    {row.motivo}
                                                                </Typography>
                                                            }

                                                        </Stack>
                                                    </td>

                                                    <td>
                                                        <IconButton
                                                            variant="solid"
                                                            color="neutral"
                                                            size='sm'
                                                            onClick={() => { setRow(row ?? []); setOpen(true); }}
                                                        >
                                                            <FormatListBulletedIcon />
                                                        </IconButton>
                                                    </td>

                                                </tr>
                                            ))
                                }

                                {
                                    (!isLoading && rows.length === 0)
                                    &&
                                    <tr>
                                        <td colSpan={9} style={{ textAlign: 'center', padding: '50px' }}>
                                            <Typography fontSize={14} fontWeight={'bold'}>No se encontraron registros</Typography>
                                        </td>
                                    </tr>
                                }

                            </tbody>

                        </Table>

                    </Grid>

                </Grid>

                <Pagination
                    gotoPage={setPage}
                    length={recordsCount}
                    pageSize={rowsPerPage}
                    setPageSize={setRowsPerPage}
                    loading={isLoading}
                />
            </Layout>

            <ModalMostrarMapa
                open={openMapa}
                setOpen={setOpenMapa}
                notificacion={row}
            />

            <ModalNotificacion
                open={open}
                setOpen={setOpen}
                notificacion={row}

            />

        </Box>
    );
}
