import { useEffect, useRef, useState } from 'react';

import { useSelector } from 'react-redux';

import { Alert, AspectRatio, Box, Button, Card, CardActions, CardOverflow, Chip, CircularProgress, Divider, FormControl, FormLabel, Grid, IconButton, Input, Snackbar, Stack, Typography } from '@mui/joy';

import styled from '@emotion/styled';

import EditRoundedIcon from '@mui/icons-material/EditRounded';
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import WarningIcon from "@mui/icons-material/Warning";
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';

import { Sidebar } from '../components/Sidebar';
import { Header } from '../components/Header';
import { Layout } from '../components/Layout';

import { FirstLoginPage } from './auth/FirstLoginPage';

import { RootState } from '../store';

import { ClearFormatPhoneNumber, FormatPhoneNumber } from '../helpers/FormatPhoneNumber';

import { ActualizarImagenPerfil, CambiarContrasena, CambiarInformacion, ObtenerPerfil } from '../connections/admin/UsuarioConnection';

import { PerfilProps } from '../interfaces/admin/UsuarioInterface';
import { SnackbarProps } from '../interfaces/ui/Snackbar';

const InputFile = styled('input')({ display: 'none' });

export const HomePage = () => {

    const { restablecerContrasena } = useSelector( ( state: RootState ) => state.auth );

    const [{ nombres, apellido1, apellido2, curp, tipoUsuario, puesto, firmaElectronica, roles }, setUsuario] = useState<PerfilProps>( {} );

    const inputFileRef = useRef<HTMLInputElement>( null );

    const [rfc, setRfc] = useState<string>('');
    const [telefonoCelular, setTelefonoCelular] = useState<string>('');

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingContrasena, setIsLoadingContrasena] = useState<boolean>(false);
    const [isLoadingInformacion, setIsLoadingInformacion] = useState<boolean>(false);
    const [isLoadingImagenPerfil, setIsLoadingImagenPerfil] = useState<boolean>(false);

    const [contrasena, setContrasena] = useState<string>('');
    const [confirmarContrasena, setConfirmarContrasena] = useState<string>('');

    const [imagenPerfil, setImagenPerfil] = useState<string>('');
    const [diasFirmaExpira, setDiasFirmaExpira] = useState<number>(0);

    const [isVisibility, setIsVisibility] = useState<boolean>(false);
    const [isVisibilityConfirm, setIsVisibilityConfirm] = useState<boolean>(false);

    const [{ type: typeSnackbar, open: openMessage, message }, setOpenMessage] = useState<SnackbarProps>({
        type: 'warning',
        message: '',
        open: false,
    });

    const handleClose = () => setOpenMessage({ type: typeSnackbar, open: false, message })

    const handleCambiarContrasena = async ( e: React.MouseEvent<HTMLAnchorElement, MouseEvent> ) => {

        e.preventDefault();  

        if( !contrasena ){
            setOpenMessage({type: 'warning', open: true, message: 'Debe escribir una contraseña' });
            return;
        } 
        else if( !confirmarContrasena ){
            setOpenMessage({type: 'warning', open: true, message: 'Debe escribir la confirmación de la contraseña' });
            return;               
        } 
        else if( contrasena !== confirmarContrasena ){                
            setOpenMessage({type: 'warning', open: true, message: 'Las contraseñas no coinciden' });
            return;                    
        }   
        else{     
            
            setIsLoadingContrasena( true );
               
            await CambiarContrasena( { contrasena } ).then( resp => {
                
                if( resp.success ){       
                        
                    setTimeout(() => {
                        
                        setOpenMessage({ type: 'success', open: true, message: 'Se cambió la contraseña correctamente' });
                        setIsLoadingContrasena( false );

                    }, 400);
                    
                }
                else{

                    setTimeout(() => {    

                        setOpenMessage({ type: 'danger', open: true, message: `Ocurrio un error, ${ resp.message ?? '' }` });
                        setIsLoadingContrasena( false );

                    }, 400);
                   
                }
            });   

        }
    }

    const handleCambiarInformacion = async ( e: React.MouseEvent<HTMLAnchorElement, MouseEvent> ) => {

        e.preventDefault();  

        if( !telefonoCelular ){
            setOpenMessage({type: 'warning', open: true, message: 'Debe escribir el teléfono celular' });
            return;
        }        
        else{     
            
            setIsLoadingInformacion( true );
               
            await CambiarInformacion( { rfc, telefonoCelular: FormatPhoneNumber( telefonoCelular ) } ).then( resp => {
                
                if( resp.success ){       
                        
                    setTimeout(() => {
                        
                        setOpenMessage({ type: 'success', open: true, message: 'Se cambió la información correctamente' });
                        setIsLoadingInformacion( false );

                    }, 400);
                    
                }
                else{

                    setTimeout(() => {    

                        setOpenMessage({ type: 'danger', open: true, message: `Ocurrio un error, ${ resp.message ?? '' }` });
                        setIsLoadingInformacion( false );

                    }, 400);
                   
                }
            });   

        }
    }

    const subirImagen = async ({ currentTarget }: React.ChangeEvent<HTMLInputElement> ) => {

        const { files } = currentTarget;

        if( files === null ) return;

        if ( files.length > 0 ) {

            const file = files[0];

            if( file.size > 300000 ){
                
                return false;
            }
            
            setIsLoadingImagenPerfil( true );

            if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') {

                let formData = new FormData();
                    formData.append('imagenPerfil', file);  

                await ActualizarImagenPerfil( formData ).then( resp => {  
                            
                    if ( resp.success ) {
                        
                        setTimeout(() => {                                     

                            setOpenMessage({ open: true, message: resp.message ?? '', type: 'success' });
                            setImagenPerfil( resp.imagen ?? '' );
                            setIsLoadingImagenPerfil( false );

                        }, 400);

                    }
                    else{

                        setTimeout(() => {               
                            
                            setOpenMessage({ open: true, message: resp.message ?? '', type: 'danger' });
                            setIsLoadingImagenPerfil( false );

                        }, 400);

                    }

                });
            }
        }
    }

    useEffect(() => {      
        
        async function Obtener(){   

            setIsLoading( true );
            
            await ObtenerPerfil().then( resp => {

                if( resp.success ){

                    setUsuario({ ...resp });
                    
                    setRfc( resp.rfc ?? '');
                    setTelefonoCelular( ClearFormatPhoneNumber( resp.telefonoCelular ?? '' ) );

                    setDiasFirmaExpira( resp.diasExpiracionFirmaElectronica ?? 0 );                    

                    setContrasena( resp.contrasena ?? '');
                    setConfirmarContrasena( resp.contrasena ?? '');
                    setImagenPerfil( resp.imagenPerfil ?? '');

                    setTimeout(() => {
                        setIsLoading( false );
                    }, 600);
                }
                else {                
                    setTimeout(() => {
                        setIsLoading( false );
                    }, 600);
               }

            });
        }

        Obtener();

    }, [ ])    

    return (   
        <>
            {
                restablecerContrasena
                ?
                    <FirstLoginPage />
                :
                    <Box sx={{ display: 'flex', minHeight: '100dvh' }}>

                        <Snackbar
                            key={ 'snackbar-login' }
                            autoHideDuration={ 2000 }
                            color={ typeSnackbar }
                            size="md"
                            variant="solid"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            open={ openMessage }
                            onClose={ handleClose }
                            startDecorator={ typeSnackbar === 'success' ? <CheckIcon /> : <WarningIcon /> }
                        >
                            { message }
                        </Snackbar>

                        <Sidebar />
                        
                        <Header />

                        <Layout
                            title={ nombres ? `HOLA, ${ nombres }` : '' }
                        >

                            <Grid container spacing={3}>
                            
                                <Grid xs={12} lg={6}>

                                    <Card>

                                        {
                                            isLoading
                                            ?
                                                <Box sx={{ textAlign: 'center', p: '200px', height: 510 }}>
                                                    <CircularProgress color='neutral' size='md' />
                                                </Box>
                                            :
                                            <>
                                                <Box sx={{ mb: 1 }}>

                                                    <Typography level="title-md">Información personal</Typography>
                                                    <Typography level="body-sm">                                    
                                                        Actualiza la información de tu perfil
                                                    </Typography>

                                                </Box>
                                            
                                                <Divider />

                                                <Stack
                                                    direction="column"
                                                    spacing={2}
                                                    sx={{ my: 1 }}
                                                >
                                                    <Stack direction="row" spacing={2}>

                                                        <Stack direction="column" spacing={1}>

                                                            <InputFile 
                                                                ref={ inputFileRef }
                                                                accept=".jpg, .png, .jpeg" 
                                                                type="file" 
                                                                onChange={ subirImagen } 
                                                            />

                                                            <AspectRatio
                                                                ratio="1"
                                                                maxHeight={108}
                                                                sx={{ flex: 1, minWidth: 108, borderRadius: '100%' }}
                                                            >
                                                                {
                                                                    isLoadingImagenPerfil
                                                                    ?
                                                                        <CircularProgress size='md' color='neutral' />
                                                                    :
                                                                        <img
                                                                            src={
                                                                                ( imagenPerfil !== '' )
                                                                                ? 
                                                                                    imagenPerfil 
                                                                                :
                                                                                    "https://static.vecteezy.com/system/resources/previews/019/896/008/original/male-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png"
                                                                            }
                                                                            loading="lazy"
                                                                            alt=""
                                                                        />
                                                                }

                                                            </AspectRatio>

                                                            <IconButton
                                                                aria-label="upload new picture"
                                                                size="sm"
                                                                variant="outlined"
                                                                color="neutral"
                                                                sx={{
                                                                    bgcolor: 'background.body',
                                                                    position: 'absolute',
                                                                    zIndex: 2,
                                                                    borderRadius: '50%',
                                                                    left: 85,
                                                                    top: 180,
                                                                    boxShadow: 'sm',
                                                                }}
                                                                onClick={ () => inputFileRef.current?.click() }
                                                            >
                                                                <EditRoundedIcon />
                                                            </IconButton>

                                                        </Stack>

                                                        <Stack spacing={1} sx={{ flexGrow: 1 }}>
                                                            <Typography level="body-md" fontWeight={'600'}>Nombre completo</Typography>
                                                            <Box>
                                                                <Typography level="body-md" sx={{ mb: 1 }}>{ nombres } { apellido1 } { apellido2 }</Typography>

                                                                <Typography level="body-md" sx={{ mt: 2, mb: 1 }}>
                                                                    <Typography fontWeight={'600'}>Clave Única de Registro de Población (CURP): </Typography> <br />
                                                                    <Typography>{ curp } </Typography>
                                                                </Typography>
                                                            
                                                                <Typography level="body-md" sx={{ mt: 2, mb: 1 }}>
                                                                    <Typography fontWeight={'600'}>Tipo de usuario:</Typography> <Typography>{ tipoUsuario }</Typography>
                                                                </Typography>

                                                                {
                                                                    puesto
                                                                    &&
                                                                        <Typography level="body-md" sx={{ mt: 2, mb: 1 }}>
                                                                            <Typography fontWeight={'600'}>Puesto:</Typography> <Typography>{ puesto }</Typography>
                                                                        </Typography>
                                                                }

                                                            </Box>

                                                        </Stack>

                                                    </Stack>                                         

                                                    <Stack spacing={1} sx={{ flexGrow: 1 }}>
                                                        <FormLabel>Clave del Registro Federal de Contribuyentes (RFC)</FormLabel>
                                                        <FormControl
                                                        sx={{
                                                            display: {
                                                                sm: 'flex-column',
                                                                md: 'flex-row',
                                                            },
                                                            gap: 2,
                                                        }}
                                                        >
                                                            <Input 
                                                                autoComplete='off' 
                                                                size="md" 
                                                                value={ rfc } 
                                                                onChange={ (e) => setRfc( e.target.value.toUpperCase() ) } 
                                                                placeholder="RFC" 
                                                            />
                                                        </FormControl>
                                                    </Stack>  
                                                    
                                                    <Stack spacing={1} sx={{ flexGrow: 1 }}>
                                                        <FormLabel>Teléfono celular</FormLabel>
                                                        <FormControl
                                                        sx={{
                                                            display: {
                                                                sm: 'flex-column',
                                                                md: 'flex-row',
                                                            },
                                                            gap: 2,
                                                        }}
                                                        >
                                                            <Input 
                                                                autoComplete='off' 
                                                                size="md" 
                                                                type='number' 
                                                                value={ telefonoCelular ? ClearFormatPhoneNumber( telefonoCelular ) : '' } 
                                                                onChange={ (e) => setTelefonoCelular( e.target.value ) } 
                                                                placeholder=""
                                                            />
                                                        </FormControl>
                                                    </Stack>  

                                                    <Box sx={{ textAlign: 'start', fontSize: 16 }}>                                            
                                                        <strong>Firma electrónica:</strong> <Chip size='md' color={ firmaElectronica ? 'success' : 'danger'} sx={{ fontWeight: 'bold' }}>
                                                            { firmaElectronica ? 'Registrada' : 'No registrada' }                                                    
                                                        </Chip>     
                                                    </Box>     

                                                    {
                                                        ( firmaElectronica && diasFirmaExpira <= 10 )
                                                        &&
                                                             <Alert                                                            
                                                                sx={{ alignItems: 'flex-start' }}
                                                                startDecorator={ <WarningIcon /> }
                                                                variant="soft"
                                                                color={ diasFirmaExpira <= 3 ? 'danger' : 'warning' }
                                                            >
                                                                <div>
                                                                    <div style={{ fontSize: 16 }}>{ 'Información' }</div>
                                                                    {
                                                                        diasFirmaExpira <= 0 
                                                                        ?
                                                                            <Typography level="body-sm" color='neutral' mt={1}>
                                                                                La firma electronica se encuentra vencida o expirada.
                                                                            </Typography>
                                                                        :
                                                                            diasFirmaExpira === 1
                                                                            ?
                                                                                <Typography level="body-sm" color='neutral' mt={1}>
                                                                                    La fecha de vencimiento o expiración de la firma electronica esta proxima queda { diasFirmaExpira } dia.
                                                                                </Typography>
                                                                            :
                                                                                <Typography level="body-sm" color='neutral' mt={1}>
                                                                                    La fecha de vencimiento o expiración de la firma electronica esta proxima quedan { diasFirmaExpira } dias.
                                                                                </Typography>
                                                                    }
                                                                </div>
                                                            </Alert>
                                                    }
                                                    
                                                    <Box sx={{ textAlign: 'end' }}>
                                                        <Button 
                                                            size="sm" 
                                                            variant="solid" 
                                                            color='neutral' 
                                                            startDecorator={ <SaveIcon /> }
                                                            loading={ isLoadingInformacion }
                                                            onClick={ handleCambiarInformacion }
                                                        >
                                                            Guardar
                                                        </Button>   
                                                    </Box>

                                                </Stack>                                            
                                            </>
                                        }

                                    </Card>

                                </Grid>
                                
                                <Grid xs={12} lg={6}>

                                    <Grid spacing={3}>

                                        <Grid xs={12} md={12}>

                                            <Card>

                                            {
                                                isLoading
                                                ?
                                                    <Box sx={{ textAlign: 'center', p: '150px', height: 315 }}>
                                                        <CircularProgress color='neutral' size='md' />
                                                    </Box>
                                                :
                                                    <>
                                                        <Box sx={{ mb: 1 }}>

                                                            <Typography level="title-md">Contraseña</Typography>
                                                            <Typography level="body-sm">                                    
                                                                Personaliza la contraseña para ingresar a la plataforma
                                                            </Typography>

                                                        </Box>
                                                    
                                                        <Divider />

                                                        <Stack
                                                            direction="column"
                                                            spacing={2}
                                                            sx={{ my: 1 }}
                                                        >
                                                            
                                                            <FormControl>

                                                                <FormLabel sx={{ fontSize: 16 }}>
                                                                    Nueva contraseña
                                                                </FormLabel>
                                                                <Input 
                                                                    type={ isVisibility ? "text" : "password" }
                                                                    name="password" 
                                                                    size="md" 
                                                                    autoComplete="off" 
                                                                    endDecorator={ 
                                                                        <IconButton onClick={ () => setIsVisibility( ( prev ) => !prev ) }> 
                                                                            {
                                                                                isVisibility
                                                                                ?
                                                                                    <VisibilityIcon /> 
                                                                                :
                                                                                    <VisibilityOffIcon />
                                                                            }
                                                                        </IconButton> 
                                                                    }
                                                                    value={ contrasena } 
                                                                    onChange={ (e) => setContrasena( e.target.value ) }  
                                                                />

                                                            </FormControl>

                                                            <FormControl sx={{ mt: 2 }}>

                                                                <FormLabel sx={{ fontSize: 16 }}>
                                                                    Confirmar contraseña
                                                                </FormLabel>
                                                                <Input 
                                                                    type={ isVisibilityConfirm ? "text" : "password" }
                                                                    name="password" 
                                                                    size="md" 
                                                                    autoComplete="off" 
                                                                    endDecorator={ 
                                                                        <IconButton onClick={ () => setIsVisibilityConfirm( ( prev ) => !prev ) }> 
                                                                            {
                                                                                isVisibilityConfirm
                                                                                ?
                                                                                    <VisibilityIcon /> 
                                                                                :
                                                                                    <VisibilityOffIcon />
                                                                            }
                                                                        </IconButton> 
                                                                    }
                                                                    value={ confirmarContrasena } 
                                                                    onChange={ (e) => setConfirmarContrasena( e.target.value ) }  
                                                                />

                                                            </FormControl>       
                                                            
                                                        </Stack>

                                                        <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>

                                                            <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>                              

                                                                <Button 
                                                                    size="sm" 
                                                                    variant="solid" 
                                                                    color='neutral'
                                                                    startDecorator={ <SaveIcon /> }
                                                                    loading={ isLoadingContrasena }
                                                                    onClick={ handleCambiarContrasena }
                                                                >
                                                                    Guardar
                                                                </Button>

                                                            </CardActions>

                                                        </CardOverflow>
                                                    </>
                                                }

                                            </Card>

                                        </Grid>                            
                                        
                                        <Grid xs={12} md={12} mt={2}>

                                            <Card>

                                                {
                                                    isLoading
                                                    ?
                                                        <Box sx={{ textAlign: 'center', p: '150px', height: 285 }}>
                                                            <CircularProgress color='neutral' size='md' />
                                                        </Box>
                                                    :
                                                        <>
                                                            <Box sx={{ mb: 1 }}>

                                                                <Typography level="title-md">Permisos</Typography>
                                                                <Typography level="body-sm">                                    
                                                                    Nivel de acceso a los módulos que tiene la plataforma
                                                                </Typography>

                                                            </Box>

                                                            <Divider />

                                                            <Stack
                                                                direction="column"
                                                                spacing={2}
                                                                sx={{ my: 1 }}
                                                            >
                                                                
                                                                <Grid container spacing={3}>

                                                                    {
                                                                        roles?.map ( ( elem, index ) => (

                                                                            <Grid key={ index } xs={6}>

                                                                                <Chip size='sm' variant="solid" startDecorator={ <WorkspacePremiumIcon />} sx={{ p: '6px' }}>
                                                                                    { elem.descripcion.substring(0, 35) + ( elem.descripcion.substring(35).length > 0 ? '....' : '' ) }
                                                                                </Chip>

                                                                            </Grid>

                                                                        ))
                                                                    }

                                                                    {
                                                                        ( roles?.length === 0 )
                                                                        &&
                                                                            <Grid xs={12} sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                                                                                <Typography>No tienes permisos asignados</Typography>
                                                                            </Grid>
                                                                    }

                                                                </Grid>
                                                                
                                                            </Stack>     
                                                        </>                     
                                                }

                                            </Card>

                                        </Grid>

                                    </Grid>

                                </Grid>

                                <Grid xs={12} lg={12}>

                                    <Card>

                                        <Box sx={{ mb: 1 }}>

                                            <Typography level="title-md">SAGA App</Typography>
                                            <Typography level="body-sm">                                    
                                                Aplicación movil de rutas de seguimiento para actuarios
                                            </Typography>

                                        </Box>
                                    
                                        <Divider />

                                        <Stack
                                            direction="column"
                                            spacing={3}              
                                        >

                                            <Grid container spacing={3}>   

                                                <Grid xs={12} md={6}>
                                        
                                                    <Box display='flex' flexDirection='column' alignItems='center'> 

                                                        <Typography mt={1} level='h4' fontSize={24} textAlign={'center'}>Descarga e Instala <br /> SAGA App</Typography>
                                                
                                                        <img 
                                                            alt='logo app poder en linea'
                                                            src={ process.env.PUBLIC_URL + '/logos/favicon.png'}
                                                            style={{ width: '90px', borderRadius: 15, marginTop: 15 }}
                                                        />

                                                        <Typography mt={2} level='body-md' textAlign={'center'} fontSize={16}>
                                                            Está aplicación concentra el seguimiento de rutas y <br /> la trámitación de cada notificación para los actuario.                                                
                                                        </Typography>

                                                    </Box>

                                                </Grid>                                               
                                                
                                                <Grid xs={12} md={6}>                                      

                                                    <Box display='flex' flexDirection='column' alignItems='center' mt={3}> 
                                                
                                                        <img 
                                                            alt='logo app poder en linea'
                                                            src={                                                             
                                                                process.env.REACT_APP_URL_BASE === 'https://saga.gob.mx'
                                                                ?
                                                                    process.env.PUBLIC_URL + '/assets/saga-app.png'
                                                                :
                                                                    process.env.PUBLIC_URL + '/assets/saga-app-pruebas.png'                                                            
                                                            }
                                                            style={{ width: '180px', borderRadius: 15, marginTop: 15 }}
                                                        />

                                                    </Box>                                         

                                                </Grid>                                                                                

                                            </Grid>                                                                 

                                        </Stack>

                                    </Card>

                                </Grid>
                            
                            </Grid>

                        </Layout>

                    </Box>
            }
        </>
    );
}
