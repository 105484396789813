import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { Box, Button, Chip, CircularProgress, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Modal, ModalClose, ModalDialog, ModalOverflow, Snackbar, Table, Tooltip, Typography } from "@mui/joy"

import FileCopyIcon from '@mui/icons-material/FileCopy';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from "@mui/icons-material/Warning";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';

import { Layout } from "../components/Layout"
import { Sidebar } from "../components/Sidebar"
import { Header } from "../components/Header"

import Pagination from '../components/Pagination';

import { FinalizarRuta, IniciarRuta, RutaNotificacionDetalle } from '../connections/comun/RutaConnection';

import { SnackbarProps } from '../interfaces/ui/Snackbar';

import { NotificacionFullProps } from '../interfaces/comun/NotificacionInterface';
import { FinalizarRutaParams, IniciarRutaParams } from '../interfaces/comun/RutaInterface';

export const RutaPage = () => {

    const navigate = useNavigate();

    const { id } = useParams();

    const [open, setOpen] = useState<boolean>(false);
    const [openConfirmacionIniciar, setOpenConfirmacionIniciar] = useState<boolean>(false);
    const [openConfirmacionFinalizar, setOpenConfirmacionFinalizar] = useState<boolean>(false);

    const [isLoadingRutaInicio, setIsLoadingRutaInicio] = useState<boolean>(false);
    const [isLoadingRutaFinalizar, setIsLoadingRutaFinalizar] = useState<boolean>(false);

    const [rows, setRows] = useState<NotificacionFullProps[]>([]);

    const [zona, setZona] = useState<string>('');
    const [idRuta, setIdRuta] = useState<number>(0);
    const [estatus, setEstatus] = useState<number>(0);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [verDocumento, setVerDocumento] = useState<string>('');
    const [reload, setReload] = useState<boolean>(false);

    const [{ type: typeSnackbar, open: openMessage, message }, setOpenMessage] = useState<SnackbarProps>({
        type: 'warning',
        message: '',
        open: false,
    });

    const handleCloseAlert = () => setOpenMessage({ type: typeSnackbar, open: false, message })

    const handleIniciarRuta = async () => {

        setIsLoadingRutaInicio(true);

        const params: IniciarRutaParams = {
            id: id ?? '',
        }

        await IniciarRuta(params).then(resp => {

            if (resp) {

                if (resp.success) {

                    setTimeout(() => {

                        const errorMessage: string = resp.message ?? '';

                        setOpenMessage({ type: 'success', open: true, message: errorMessage });

                        setIsLoadingRutaInicio(false);

                        setOpenConfirmacionIniciar(false);
                        setReload(true);

                    }, 400);

                }
                else {

                    setTimeout(() => {

                        const errorMessage: string = resp.message ?? '';

                        if (errorMessage.includes('Ya se encuentra una ruta iniciada')) {
                            setOpenMessage({ type: 'warning', open: true, message: errorMessage });
                        }
                        else {
                            setOpenMessage({ type: 'danger', open: true, message: errorMessage });
                        }

                        setIsLoadingRutaInicio(false);

                        setOpenConfirmacionIniciar(false);

                    }, 400);
                }
            }
        })

    }

    const handleIFinalizarRuta = async () => {

        setIsLoadingRutaFinalizar(true);

        const params: FinalizarRutaParams = {
            id: id ?? '',
        }

        await FinalizarRuta(params).then(resp => {

            if (resp) {

                if (resp.success) {

                    setTimeout(() => {

                        const errorMessage: string = resp.message ?? '';

                        setOpenMessage({ type: 'success', open: true, message: errorMessage });

                        setIsLoadingRutaFinalizar(false);

                        setOpenConfirmacionFinalizar(false);
                        setReload(true);

                    }, 400);

                }
                else {

                    setTimeout(() => {

                        const errorMessage: string = resp.message ?? '';

                        setOpenMessage({ type: 'danger', open: true, message: errorMessage });

                        setIsLoadingRutaFinalizar(false)

                        setOpenConfirmacionFinalizar(false);

                    }, 400);
                }
            }
        })

    }

    useEffect(() => {

        async function obtener() {

            setIsLoading(true);

            await RutaNotificacionDetalle(id ?? "").then(resp => {

                setTimeout(() => {

                    setRows(resp.notificaciones ?? []);

                    setIdRuta(resp.idRuta ?? 0);
                    setZona(resp.zona ?? '');
                    setEstatus(resp.idEstatus ?? 0);

                    setIsLoading(false);

                }, 400);

            });

        }

        if (id) {
            obtener();
        }

    }, [id])

    useEffect(() => {

        async function obtener() {

            setIsLoading(true);

            await RutaNotificacionDetalle(id ?? "").then(resp => {

                setTimeout(() => {

                    setRows(resp.notificaciones ?? []);

                    setIdRuta(resp.idRuta ?? 0);
                    setZona(resp.zona ?? '');
                    setEstatus(resp.idEstatus ?? 0);

                    setIsLoading(false);
                    setReload(false);

                }, 400);

            });

        }

        if (id && reload) {
            obtener();
        }

    }, [id, reload])

    return (

        <Box sx={{ display: 'flex', minHeight: '100dvh' }}>

            <Snackbar
                key={'snackbar-login'}
                autoHideDuration={2500}
                color={typeSnackbar}
                size="md"
                variant="solid"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={openMessage}
                onClose={handleCloseAlert}
                startDecorator={typeSnackbar === 'success' ? <CheckIcon /> : <WarningIcon />}
            >
                {message}
            </Snackbar>

            <Sidebar />

            <Header />

            <Layout title={
                !isLoading
                    ?
                    <Typography fontSize={26}> Ruta-{idRuta} (Zona {zona}) </Typography>
                    :
                    <Typography fontSize={26}> Ruta </Typography>
            }
            >

                <Grid container spacing={1}>

                    <Grid xs={0} md={6} lg={10}></Grid>

                    {
                        (estatus === 7 && !isLoading)
                        &&
                        <Grid xs={12} md={3} lg={2} sx={{ display: 'none' }}>

                            <Button
                                size='lg'
                                color='neutral'
                                fullWidth
                                startDecorator={<DoneOutlineIcon />}
                                onClick={() => setOpenConfirmacionIniciar(true)}
                            >
                                Iniciar ruta
                            </Button>

                        </Grid>
                    }

                    {
                        (!isLoading && (rows.length === rows.filter((e) => e.idEstatus === 10).length) && estatus === 8)
                        &&
                        <Grid xs={12} md={3} lg={2}>

                            <Button
                                size='lg'
                                color='neutral'
                                fullWidth
                                startDecorator={<DoneOutlineIcon />}
                                onClick={() => setOpenConfirmacionFinalizar(true)}
                            >
                                Finalizar ruta
                            </Button>

                        </Grid>
                    }

                    <Grid xs={12}>

                        <Button
                            variant='plain'
                            color='neutral'
                            startDecorator={<ArrowBackIcon />}
                            onClick={() => navigate(-1)}
                        >
                            Regresar
                        </Button>

                    </Grid>

                    <Grid xs={12} mt={1}>

                        <Table
                            aria-label="striped table"
                            stripe={'even'}
                            borderAxis='x'
                            size='md'
                            sx={{
                                width: { xs: '100%', md: '100%' },
                                display: { xs: 'block', md: 'inline' },
                                overflowX: { xs: 'auto', md: 'hidden' },
                            }}
                        >

                            <thead>

                                <tr>
                                    <th style={{ width: '3%', textAlign: 'center' }}>ID</th>
                                    <th style={{ width: '20%' }}>Persona a notificar</th>
                                    <th style={{ width: '25%' }}>Domicilio</th>
                                    <th style={{ width: '10%' }}>Notificación</th>
                                    <th style={{ width: '5%' }}>Zona</th>
                                    <th style={{ width: '5%', textAlign: 'center' }}>Cédula de <br /> notificación</th>
                                    <th style={{ width: '5%', textAlign: 'center' }}>Documento</th>
                                    <th style={{ width: '5%' }}>Estatus</th>
                                    <th style={{ width: '0%' }}></th>
                                    <th style={{ width: '5%' }}></th>
                                </tr>

                            </thead>

                            <tbody>

                                {
                                    isLoading
                                        ?
                                        <tr>
                                            <td colSpan={9} style={{ textAlign: 'center', padding: '250px' }}>
                                                <CircularProgress color='neutral' size='md' />
                                            </td>
                                        </tr>
                                        :

                                        (
                                            rowsPerPage > 0
                                                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                : rows
                                        )
                                            .map((row, index) => (
                                                <tr key={index}>

                                                    <td style={{ fontWeight: 'bold', textAlign: 'center' }}>{row.idNotificacion}</td>

                                                    <td>
                                                        <Typography fontSize={14} fontWeight='bold'> {row.persona} </Typography>
                                                        <Typography fontSize={14}> ({row.tipoParte}) </Typography>
                                                    </td>

                                                    <td>{row.domicilio}</td>

                                                    <td>
                                                        <Typography fontSize={14} fontWeight='bold'> {row.referencia} </Typography>
                                                        <Typography fontSize={14}> ({row.tipoNotificacion}) </Typography>
                                                    </td>

                                                    <td> {row.zona} </td>

                                                    <td style={{ textAlign: 'center' }}>

                                                        <IconButton variant='solid' size='md' onClick={() => { setOpen(true); setVerDocumento(row.cedulaNotificacion ?? ''); }}>
                                                            <FileCopyIcon />
                                                        </IconButton>

                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>

                                                        {
                                                            row.idTipoNotificacion === 1
                                                            &&
                                                            <IconButton variant='solid' size='md' onClick={() => { setOpen(true); setVerDocumento(row.documento ?? ''); }}>
                                                                <FileCopyIcon />
                                                            </IconButton>
                                                        }

                                                    </td>

                                                    <td>
                                                        <Chip variant='outlined' size='lg'>
                                                            {
                                                                row.estatus
                                                            }
                                                        </Chip>
                                                    </td>

                                                    <td>
                                                        {
                                                            (row.replanificar && row.idEstatus === 10)
                                                            &&
                                                            <>
                                                                <Tooltip title="Re-planificado">

                                                                    <EventRepeatIcon sx={{ color: '#ffa138' }} />

                                                                </Tooltip>
                                                            </>
                                                        }
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>

                                                        {
                                                            (row.idEstatus === 9 || row.idEstatus === 10)
                                                            &&
                                                            <IconButton variant="solid" onClick={() => navigate(`/notificacion/${row.id}`)}>
                                                                <ArrowForwardIcon />
                                                            </IconButton>
                                                        }

                                                    </td>

                                                </tr>
                                            ))
                                }

                                {
                                    (!isLoading && rows.length === 0)
                                    &&
                                    <tr>
                                        <td colSpan={6} style={{ textAlign: 'center', padding: '50px' }}>
                                            <Typography fontSize={14} fontWeight={'bold'}>No se encontraron registros</Typography>
                                        </td>
                                    </tr>
                                }

                            </tbody>

                        </Table>

                    </Grid>

                </Grid>

                <Pagination
                    gotoPage={setPage}
                    length={rows.length}
                    pageSize={rowsPerPage}
                    setPageSize={setRowsPerPage}
                />

            </Layout>

            <Modal
                disableEscapeKeyDown
                open={open}
                onClose={(_, r) => {
                    if (r === 'backdropClick') return;
                    setOpen(false)
                }}
                sx={{ zIndex: 9999999, backdropFilter: 'blur(0px)' }}
            >
                <ModalOverflow>

                    <ModalDialog
                        aria-labelledby="server-modal-title"
                        aria-describedby="server-modal-description"
                        layout="center"
                        size='lg'
                    >
                        <ModalClose />
                        <DialogTitle>  </DialogTitle>

                        <DialogContent>

                            <Grid container mt={2} sx={{ width: '800px' }}>

                                <Grid xs={12}>

                                    <iframe title='acuse' src={verDocumento} height="500px" width="100%"></iframe>

                                </Grid>

                            </Grid>

                        </DialogContent>

                    </ModalDialog>

                </ModalOverflow>

            </Modal>

            <Modal
                disableEscapeKeyDown
                open={openConfirmacionIniciar}
                onClose={(_, r) => {
                    if (r === 'backdropClick') return;
                    setOpenConfirmacionIniciar(false)
                }}
                sx={{ zIndex: 9999999, backdropFilter: 'blur(0px)' }}
            >

                <ModalDialog variant="outlined" role="alertdialog" sx={{ width: 400 }}>

                    <DialogTitle>
                        <WarningRoundedIcon />
                        Confirmación
                    </DialogTitle>

                    <Divider />

                    <DialogContent>
                        ¿Desea iniciar la ruta?
                    </DialogContent>

                    <DialogActions>

                        <Button sx={{ bgcolor: '#004360', ':hover': { bgcolor: 'rgba(0, 67, 96, 0.9)' } }} onClick={handleIniciarRuta} loading={isLoadingRutaInicio} >
                            Aceptar
                        </Button>

                        <Button variant="plain" color="neutral" onClick={() => setOpenConfirmacionIniciar(false)} >
                            Cancelar
                        </Button>

                    </DialogActions>

                </ModalDialog>

            </Modal>

            <Modal
                disableEscapeKeyDown
                open={openConfirmacionFinalizar}
                onClose={(_, r) => {
                    if (r === 'backdropClick') return;
                    setOpenConfirmacionFinalizar(false)
                }}
                sx={{ zIndex: 9999999, backdropFilter: 'blur(0px)' }}
            >

                <ModalDialog variant="outlined" role="alertdialog" sx={{ width: 400 }}>

                    <DialogTitle>
                        <WarningRoundedIcon />
                        Confirmación
                    </DialogTitle>

                    <Divider />

                    <DialogContent>
                        ¿Desea finalizar la ruta?
                    </DialogContent>

                    <DialogActions>

                        <Button sx={{ bgcolor: '#004360', ':hover': { bgcolor: 'rgba(0, 67, 96, 0.9)' } }} onClick={handleIFinalizarRuta} loading={isLoadingRutaFinalizar} >
                            Aceptar
                        </Button>

                        <Button variant="plain" color="neutral" onClick={() => setOpenConfirmacionFinalizar(false)} >
                            Cancelar
                        </Button>

                    </DialogActions>

                </ModalDialog>

            </Modal>

        </Box>

    )
}
